import { useEffect, useCallback, useState } from 'react';

// const options = {
//   enableHighAccuracy: true,
//   timeout: 5000,
//   maximumAge: 0,
// };

export const useGetGeolocation = () => {
  const [isGranted, setIsGranted] = useState<boolean>(false);

  const handleGeolocationSuccess = useCallback(
    (position: GeolocationPosition) => {
      const { latitude, longitude } = position.coords;
      console.log(latitude, longitude);
    },
    []
  );

  const handleGeolocationError = useCallback(
    (err: GeolocationPositionError) => {
      console.log(err);
    },
    []
  );

  useEffect(() => {
    if ('geolocation' in window.navigator) {
      setIsGranted(true);
    }
  }, []);

  useEffect(() => {
    if (isGranted) {
      navigator.geolocation.getCurrentPosition(
        handleGeolocationSuccess,
        handleGeolocationError
      );
    }
  }, [isGranted, handleGeolocationSuccess, handleGeolocationError]);
};
