import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Router } from 'app/Router';

import { useGetGeolocation } from 'hooks/useGetGeolocation';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  useGetGeolocation();

  return (
    <QueryClientProvider client={queryClient}>
      <Router />
    </QueryClientProvider>
  );
}

export default App;
