import React, { Suspense } from 'react';
import { Navigate, createBrowserRouter, RouterProvider } from 'react-router-dom';
import loadable from '@loadable/component';
import { AppSpinner } from 'components/AppSpinner';

const DefaultPage = loadable(() => import('pages/DefaultPage'), {
  resolveComponent: (component) => component.DefaultPage,
});

const ExamplePage = loadable(() => import('pages/ExamplePage'), {
  resolveComponent: (component) => component.ExamplePage,
});

const ProductPage = loadable(() => import('pages/ProductPage'), {
  resolveComponent: (component) => component.ProductPage,
});

const PrizesPage = loadable(() => import('pages/PrizesPage'), {
  resolveComponent: (component) => component.PrizesPage,
});

const WalletPage = loadable(() => import('pages/WalletPage'), {
  resolveComponent: (component) => component.WalletPage,
});

const AccountPage = loadable(() => import('pages/AccountPage'), {
  resolveComponent: (component) => component.AccountPage,
});

const FundProductPage = loadable(() => import('pages/funds/ProductPage'), {
  resolveComponent: (component) => component.ProductPage,
});

const FundSupportPage = loadable(() => import('pages/funds/SupportPage'), {
  resolveComponent: (component) => component.PrizesPage,
});

const FundUpdatesPage = loadable(() => import('pages/funds/UpdatesPage'), {
  resolveComponent: (component) => component.WalletPage,
});

const FundAccountPage = loadable(() => import('pages/funds/AccountPage'), {
  resolveComponent: (component) => component.AccountPage,
});

const NotFoundPage = loadable(() => import('pages/NotFoundPage'), {
  resolveComponent: (component) => component.NotFoundPage,
});

export const Router = () => {
  const router = createBrowserRouter([
    {
      path: '/:companyId',
      element: <DefaultPage />,
    },
    {
      path: '/verify/:companyId',
      element: <ExamplePage />,
    },
    {
      path: '/product',
      element: <ProductPage />,
    },
    {
      path: '/prizes',
      element: <PrizesPage />,
    },
    {
      path: '/wallet',
      element: <WalletPage />,
    },
    {
      path: '/account',
      element: <AccountPage />
    },
    {
      path: '/fund',
      children: [
        {
          path: 'product',
          element: <FundProductPage />
        },
        {
          path: 'support',
          element: <FundSupportPage />
        },
        {
          path: 'updates',
          element: <FundUpdatesPage />
        },
        {
          path: 'account',
          element: <FundAccountPage />
        },
      ]
    },
    {
      path: '/404',
      element: <NotFoundPage />
    },
    {
      path: '*',
      element: <Navigate to="/404" />,
    },
  ]);
  return (
    <Suspense fallback={<AppSpinner />}>
      <RouterProvider router={router} />
    </Suspense>
  );
};
